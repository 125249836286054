import React from "react";
import { NavBar } from "../components";
import { Intro, About } from "../sections";
import Services from "../sections/services";
import "./home.css";
import Blog from "../sections/blog";
import Footer from "../sections/footer";

const Home = () => {
  return (
    <>
      <header className={"header fixed-top"}>
        <NavBar />
      </header>
      <main style={{ paddingLeft: 20, paddingRight: 20 }}>
        <div data-spy="scroll" data-target="#navCon">
          <div id="intro" className={"section section-intro"}>
            <Intro />
          </div>
          <div id="about" className={"section section-about"}>
            <About />
          </div>
          <div id="services" className={"section section-services"}>
            <Services />
          </div>
          <div id="blog" className={"section section-blog"}>
            <Blog />
          </div>
          <div id="footer" className={"section-footer"}>
            <Footer />
          </div>
        </div>
      </main>
    </>
  );
};

export default Home;
