import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Scrollspy from "react-scrollspy";
import logo from "../assets/imgs/xelestial_logo_web.png";
import "./navbar.css";

const NavBar = () => {
  return (
    <Container>
      <Navbar collapseOnSelect expand="lg" bg="transparent" variant="dark">
        <Navbar.Brand href="/">
          <img
            alt=""
            src={logo}
            width="150"
            // height="40"
            className="d-inline-block align-top"
          />{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            {/* <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link> */}
          </Nav>

          <Nav id="navCon">
            <Scrollspy
              items={["intro", "about"]}
              currentClassName="link-active"
              scrolledPastClassName="nav-link"
              className="navbar-nav nav-head"
            >
              <Nav.Link href="#intro">Home</Nav.Link>
              <Nav.Link href="#about">About</Nav.Link>
              {/* <Nav.Link href="#service">Service</Nav.Link> */}
              {/* <Nav.Link href="#experience">Experience</Nav.Link> */}
              {/* <Nav.Link href="#works">Works</Nav.Link> */}
              <Nav.Link href="https://blog.xelestial.co">Blog</Nav.Link>
              <Nav.Link href="https://blog.xelestial.co/privacy-policy">
                Privacy Policy
              </Nav.Link>
            </Scrollspy>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
};

export default NavBar;
