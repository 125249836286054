import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./footer.css";

const Footer = () => {
  return (
    <section id={"footer-section"} className={"footer-section"}>
      <Container
        data-aos="fade-up"
        data-aos-duration="1000"
        className={"footer"}
      >
        <div className={"heading"}>
          <h2 className={"section-title"}>
            Xelestial Tech Services Private Limited
          </h2>
        </div>
        <div className="spacer"></div>
        <Row>
          <Col className={"footer-col"}>
            <Col className="info">2138 Langwan</Col>
            <Col className="info">Hamirpur Himachal Pradesh </Col>
            <Col className="info">177001 India</Col>
          </Col>
          <Col className={"footer-col"}>
            <Col className="info">U72900HP2022PTC009432</Col>
            <Col className="info">xelestial © 2023</Col>
            <Col className="info"></Col>
          </Col>
          <Col className={"footer-col"}>
            <Col className="info">
              <a href="mailto:x@xelestial.co">x@xelestial.co</a>
            </Col>
            <Col className="info">
              <a href="https://twitter.com/xelestialco">@xelestialco</a>
            </Col>
            <Col className="info"></Col>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Footer;
