import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Placeholder,
  Button,
} from "react-bootstrap";
// import Placeholder from "react-bootstrap/Placeholder";
import "./blog.css";

const Blog = () => {
  const [content, setContent] = useState(["", "", ""]);

  useEffect(() => {
    fetch(
      "https://blog.xelestial.co/ghost/api/content/posts/?key=52736a40f49063d32699bcb124&fields=title,url,feature_image,excerpt&limit=3"
    )
      .then((response) => response.json())
      .then((result) => {
        let posts = result["posts"];
        setContent(posts);
      });
  }, []);

  return (
    <section id={"about-section"} className={"about-section"}>
      <Container
        data-aos="fade-up"
        data-aos-duration="1000"
        className={"about"}
      >
        <div className={"heading"}>
          <h2 className={"section-title"}>Our Publication</h2>
        </div>
        <div className="spacer"></div>
        <Row>
          {content.map((blog) => {
            if (blog?.url) {
              return (
                <Col lg className="blog-container" key={blog.url}>
                  <Card
                    className="text-center blog-card"
                    onClick={() => (window.location.href = blog.url)}
                  >
                    {blog.feature_image ? (
                      <Card.Img
                        className="card-img"
                        variant="top"
                        src={blog.feature_image}
                      />
                    ) : null}
                    <Card.Body>
                      <Card.Title className="blog-card-title">
                        {blog.title}
                      </Card.Title>
                      <Card.Text>{blog.excerpt}</Card.Text>
                      <Button variant="secondary" href={blog.url}>
                        Continue Reading
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              );
            } else {
              return (
                <Col lg className="blog-container" key={blog.url}>
                  <Card className="text-center blog-card">
                    <Card.Img
                      className="card-img"
                      variant="top"
                      src={blog.feature_image}
                    />

                    <Card.Body>
                      <Placeholder as={Card.Title} animation="glow">
                        <Placeholder xs={6} />
                      </Placeholder>
                      <Placeholder as={Card.Text} animation="glow">
                        <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
                        <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                        <Placeholder xs={8} />
                      </Placeholder>
                      {/* <Button variant="primary">Go somewhere</Button> */}
                      <Placeholder.Button xs={4} aria-hidden="true" />
                    </Card.Body>
                  </Card>
                </Col>
              );
            }
          })}
        </Row>
      </Container>
    </section>
  );
};

export default Blog;
