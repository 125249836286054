import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import logoX from "../assets/imgs/logoX.png";

import "./services.css";

const Services = () => {
  return (
    <section id={"about-section"} className={"about-section"}>
      <Container data-aos="fade-up" data-aos-duration="1000" class={"about"}>
        <div className={"heading"}>
          <h2 className={"section-title"}>Our Services</h2>
        </div>
        <div className="spacer"></div>
        <Row>
          <Col lg className="service-container">
            <Card className="text-center service-card">
              <Card.Body>
                <Card.Title className="service-card-title">
                  From Idea to AppStore
                </Card.Title>
                <Card.Text>Full App Design and Development</Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col lg className="service-container">
            <Card className="text-center service-card">
              <Card.Body>
                <Card.Title className="service-card-title">
                  Performance Optimization
                </Card.Title>
                <Card.Text>
                  Startup-time, Animation and overall smoothness optimization
                  for apps.
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col lg className="service-container">
            <Card className="text-center service-card">
              <Card.Body>
                <Card.Title className="service-card-title">
                  Consulting
                </Card.Title>
                <Card.Text>
                  One-on-one consulting with a React Native, iOS or Android
                  expert and bug fixing
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="row-2">
          <Col lg className="service-container">
            <Card className="text-center service-card">
              <Card.Body>
                <Card.Title className="service-card-title">Design</Card.Title>
                <Card.Text>
                  Our team will help your ideas turned to design.
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col lg className="service-container">
            <Card className="text-center service-card">
              <Card.Body>
                <Card.Title className="service-card-title">
                  React Native | Expo
                </Card.Title>
                <Card.Text>
                  Our primary framework for app develiopment - the frontend
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col lg className="service-container">
            <Card className="text-center service-card">
              <Card.Body>
                <Card.Title className="service-card-title">
                  AWS/DigitalOcean
                </Card.Title>
                <Card.Text>
                  We'll help you build best backend, for the product.
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Services;
