import React from "react";
import { Container, Row, Button, ProgressBar } from "react-bootstrap";
import logoX from "../assets/imgs/logoX.png";

import "./about.css";

const About = () => {
  return (
    <section id={"about-section"} className={"about-section"}>
      <Container data-aos="fade-up" data-aos-duration="1000" class={"about"}>
        <div className={"heading"}>
          <h2 className={"section-title"}>About Us</h2>
        </div>
        <div className="spacer"></div>
        <Row>
          <div
            className={"col-md-3"}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className={"avatar-2 text-center text-md-left"}>
              <img class={"about-avatar"} src={logoX} />
            </div>
          </div>
          <Container
            className={"about-col col-md-9 triangle-left-md triangle-top-sm"}
          >
            <Row>
              <div className={"col"}>
                <p style={{ fontSize: 30 }}>
                  We are a group of techies who built this team. We know the
                  tools, and we strive to build the best for our customers. Our
                  team has been building in this space for the last 7 years.
                </p>
                <p style={{ fontSize: 17, color: "#d9d9d9" }}>
                  We built xelestial to provide innovative solutions to the
                  modern-day problems. Our mission is to create products and
                  services that enhance the lives of our customers and make the
                  world a better place. We have a team of experienced
                  developers, designers, and engineers who are committed to
                  creating and delivering the best products and services. They
                  strive to stay ahead of the competition and create solutions
                  that are both innovative and reliable.
                </p>
              </div>
            </Row>
          </Container>
        </Row>
      </Container>
    </section>
  );
};

export default About;
